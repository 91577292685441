import { useEffect, useState } from "react";
import { CountdownTimerCircle } from "../../components";

export const CountdownTimer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "Nov 12, 2022 21:00:00";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="countdownTimer">
      <div className="countdownTimer__message">
        <p>Solo falta:</p>
      </div>
      <div className="countdownTimer__circles">
        <CountdownTimerCircle letter={"Días"} number={days} />
        <CountdownTimerCircle letter={"Horas"} number={hours} />
        <CountdownTimerCircle letter={"Minutos"} number={minutes} />
        <CountdownTimerCircle letter={"Segundos"} number={seconds} />
      </div>
      <div className="countdownTimer__message">
        <p>Para el gran tonazo del año</p>
      </div>
    </section>
  );
};
