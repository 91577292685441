import { useEffect } from "react";
import {
  Background,
  CountdownTimer,
  FooterBirthday,
  HeaderBirthday,
} from "../containers";

export default function Home() {
  useEffect(() => {
    document.title = "Mi cumple";
  }, []);
  return (
    <>
      <Background />
      <HeaderBirthday />
      <CountdownTimer />
      <FooterBirthday />
    </>
  );
}
