export const CountdownTimerCircle = ({ letter, number }) => {
  return (
    <div className="countdownTimerCircle">
      <div className="countdownTimerCircle__letter">
        <p>{letter}</p>
      </div>
      <div className="countdownTimerCircle__number">
        <p>{number}</p>
      </div>
    </div>
  );
};
