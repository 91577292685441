import React from "react";

export const HeaderBirthday = () => {
  return (
    <section className="headerBirthday">
      <div className="headerBirthday__date">
        <p>12 de Noviembre 2022</p>
      </div>
      <div className="headerBirthday__schedule">
        <p>desde las 09:00 PM</p>
      </div>
      <div className="headerBirthday__title">
        <p>Mi cumpleaños</p>
      </div>
      <div className="headerBirthday__description">
        <p>
          Hola fiestero, te saluda Isabel. Te invito a mi fiesta por mi
          cumpleaños número ?.
        </p>
      </div>
    </section>
  );
};
