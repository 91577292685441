import React from "react";

export const Background = () => {
  return (
    <div className="background">
      <img
        src="https://guiauniversitaria.mx/wp-content/uploads/2019/12/dj-profesional.jpg"
        alt=""
      />
    </div>
  );
};
