import { IconContext } from "react-icons";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BiCalendarEvent, BiMap } from "react-icons/bi";
export const FooterBirthday = () => {
  return (
    <section className="footerBirthday">
      <IconContext.Provider value={{ color: "#FFFFFF", size: "50px" }}>
        <div className="footerBirthday__map">
          <p>La fiesta es aqui:</p>
          <a href="https://www.google.com/maps/d/u/0/edit?mid=1uAV7s4CMIxx_nzv_wdIDKyPR8VW6QAg&usp=sharing">
            <BiMap />
          </a>
        </div>
        <div className="footerBirthday__whatsapp">
          <p>Ingresa al grupo de whatsapp:</p>
          <a href="https://chat.whatsapp.com/IuAB9XrVPb1KuUTxUBaNHe">
            <AiOutlineWhatsApp />
          </a>
        </div>
        <div className="footerBirthday__calendar">
          <p>Agenda aquí:</p>
          <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Cumple+de+Isa&dates=20221113T020000Z/20221113T030000Z&details&pli=1&sf=true">
            <BiCalendarEvent />
          </a>
        </div>
      </IconContext.Provider>
    </section>
  );
};
